/* eslint-disable */
import bootstrap from 'bootstrap';
import AOS from 'aos';
import Splide from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';
import 'masonry-layout';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgHash from 'lightgallery/plugins/hash';
import lgShare from 'lightgallery/plugins/share';

// Font Awesome Config
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPrint, faChevronLeft, faChevronRight, faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faArrowRight, faArrowLeft, faPhone, faTags, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faStore, faComments, faEnvelopeOpen, faQuoteRight, faQuoteLeft, faFilter, faPencilAlt, faEdit, faCartPlus, faTruck, faMobileAlt, faLongArrowRight, faLongArrowLeft, faCalendarAlt, faExpandWide, faCompressWide, faArrowFromRight, faMapMarker, faMap, faEnvelope, faInfoCircle, faGraduationCap, faHandsHelping, faUsers, faLockOpenAlt, faBadgeDollar, faSparkles, faCrown, faPersonCarry, faCashRegister, faBagsShopping, faParking } from '@fortawesome/pro-regular-svg-icons';
import { faUser, faShoppingCart, faLocationArrow, faExclamationCircle, faCheck, faStar } from '@fortawesome/pro-solid-svg-icons';
import { faUfoBeam } from '@fortawesome/pro-light-svg-icons';
import { faFacebookSquare, faInstagram, faTwitterSquare, faFacebook, faTwitter, faGoogle, faYelp, faMicrosoft, faLinkedin, faPinterest, faYoutube, faVimeo, faSnapchat, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

// Icons with multiple styles
//import { faChevronRight as farChevronRight} from '@fortawesome/pro-regular-svg-icons';

//add icons to lib
library.add(faEnvelope, faMap, faMapMarker, faExclamationCircle, faLocationArrow, faChevronRight, faChevronLeft, faClock, faAddressCard, faFireAlt, faFire, faSnowflake, faWind, faAirConditioner, faLeaf, faBuilding, faShower, faTools, faCannabis, faCookieBite, faEyeDropper, faJoint, faPumpSoap, faFlask, faMapMarkerAlt, faStar, faArrowRight, faArrowLeft, faPhone, faUser, faTags, faPrint, faUserTie, faMapMarkedAlt, faSortDown, faFileDownload, faShoppingCart, faStore, faComments, faFacebookSquare, faTwitterSquare, faFacebook, faTwitter, faInstagram, faGoogle, faYelp, faBuilding, faEnvelopeOpen, faMicrosoft, faLinkedin, faPinterest, faYoutube, faVimeo, faSnapchat, faQuoteRight, faFilter, faPencilAlt, faEdit, faCartPlus, faExternalLinkAlt, faCheck, faTruck, faMobileAlt, faLongArrowRight, faCalendarAlt, faLongArrowLeft, faQuoteLeft, faExpandWide, faCompressWide, faArrowFromRight, faUfoBeam, faTiktok, faInfoCircle, faGraduationCap, faHandsHelping, faUsers, faLockOpenAlt, faBadgeDollar, faSparkles, faCrown, faPersonCarry, faCashRegister, faBagsShopping, faParking);

dom.watch();

export default {
	init() {
		// JavaScript to be fired on all pages
		// init aos
		AOS.init({
			disable: 'mobile',
			duration: 750,
			offset: 50,
			once: true,
		});
		setTimeout(function () {
			AOS.refresh();
		}, 500);

		$('#MuteButton').on('click', function () {
			console.log('button clicked');
			var button = document.getElementById("MuteButton")
			var video = document.getElementById("bgVideo")

			if (video.muted) {
				video.muted = false;
			} else {
				video.muted = true;
			}
			console.log(video.muted);
			button.classList.toggle('muted');
		});

		//add arrows for ul.arrow-list
		$('.arrow-list li').prepend('<i class="far fa-arrow-right me-1 color-teal"></i>');

		// Scroll to tabContent on mobile when nav item is clicked
$('.tabs .nav-link').on('click', function (e) {
	e.preventDefault();
	console.log('clicked');
	if ($(window).width() < 991) {
			var targetOffset = $('#tabContent').offset().top - 175;
			$('html, body').animate({ scrollTop: targetOffset }, 600);
	}
	// Show the tab explicitly
	$(this).tab('show');
});

// Trigger the tab switch when the circle-icon is clicked
$('.tabs .circle-icon').on('click', function (e) {
	e.preventDefault(); // Prevent any default action if there's an anchor
	$(this).siblings('.nav-link').trigger('click');
});

		/****
		*************
		*************
				Nav Functions
		*************
		*************
		*****/
		// off canvas (not currently used)
		$('#desktopToggler').click(function () {
			$('.off-canvas-transform-menu').addClass('nav-flex');
			$('#siteVisibleArea').removeClass('site-visible-area-reverse');
			$('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse');
			$('#siteVisibleArea').addClass('site-visible-area');
			$('#siteVisibleAreaInner').addClass('site-visible-area-inner');
			$('#canvasX').delay(500).fadeIn();
			$('#offCanvasMenu').delay(500).hide().animate({
				left: 'auto',
				opacity: 'show',
			}, 1000);
		});
		var mobileClicked = 0;
		$('#mobileToggler').click(function () {
			if (mobileClicked == 0) {
				$('#siteVisibleArea').removeClass('site-visible-area-reverse-mobile');
				$('#siteVisibleAreaInner').removeClass('site-visible-area-inner-reverse-mobile');
				$('#siteVisibleArea').addClass('site-visible-area-mobile');
				$('#siteVisibleAreaInner').addClass('site-visible-area-inner-mobile');
				$('#offCanvasMobile').delay(500).hide().animate({
					left: 'auto',
					opacity: 'show',
				}, 1000);
				mobileClicked = 1;
			} else if (mobileClicked == 1) {
				$('#siteVisibleArea').addClass('site-visible-area-reverse-mobile');
				$('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse-mobile');
				$('#siteVisibleArea').removeClass('site-visible-area-mobile');
				$('#siteVisibleAreaInner').removeClass('site-visible-area-inner-mobile');
				$('#offCanvasMenu').hide();
				mobileClicked = 0;
			}
		});
		$('#canvasX').click(function () {
			$('.off-canvas-transform-menu').removeClass('nav-flex');
			$('#siteVisibleArea').addClass('site-visible-area-reverse');
			$('#siteVisibleAreaInner').addClass('site-visible-area-inner-reverse');
			$('#siteVisibleArea').removeClass('site-visible-area');
			$('#siteVisibleAreaInner').removeClass('site-visible-area-inner');
			$('#canvasX').hide();
			$('#offCanvasMenu').hide();
		});
		// dropdown on hover

		// Transparent nav scrolling function
		//caches a jQuery object containing the header element
		var transNav = $('#topLevelNav');
		var transNavHeight = transNav.outerHeight();
		var pageHeader = $('.page-header');
		var pageHeaderPadding = transNavHeight + 'px';
		// if trans nav, set initial page header top padding
		if ($('.trans-nav')[0]) {
			pageHeader.css('padding-top', pageHeaderPadding);
		}
		$(window).on('load scroll', function () {
			var transScroll = $(window).scrollTop();
			if (transScroll > 0.00) {
				transNav.removeClass('trans-top');
				transNav.addClass('trans-scrolling');
			} else {
				transNav.addClass('trans-top');
				transNav.removeClass('trans-scrolling');
			}
		});
		// Shrink on scroll nav function
		//caches a jQuery object containing the header element
		var shrinkNav = $('#topLevelNav');
		var shrinkNavbar = $('#site-navigation');
		if (shrinkNav.hasClass('shrink-on-scroll')) {
			$(window).on('load scroll', function () {
				var shrinkScroll = $(window).scrollTop();
				if (shrinkScroll > 0.00) {
					shrinkNav.addClass('nav-shrink');
					shrinkNavbar.removeClass('py-3');
					shrinkNavbar.addClass('py-2');
					return false;
				} else {
					shrinkNav.removeClass('nav-shrink');
					shrinkNavbar.addClass('py-3');
					shrinkNavbar.removeClass('py-2');
					return false;
				}
			});
		}
		// Hide topbar on scroll
		//caches a jQuery object containing the header element
		var hideTopbar = $('.topbar-hide');
		var topbarHidden = false;
		if (hideTopbar) {
			if (topbarHidden != true) {
				$(window).scroll(function () {
					var topbarOffset = $(window).scrollTop();
					if (topbarOffset > 50.00) {
						hideTopbar.css('display', 'none');
						topbarHidden = true;
						return false;
					}
					else if (topbarOffset < 10.00) {
						hideTopbar.css('display', 'block');
						return false;
					}
				});
			}
		}
		/****
		*************
		*************
				Add ADA Compliance to h1, h2, h3 etc div tags
		*************
		*************
		*****/
		$('.h1').each(function () {
			var h1Title = $(this);
			h1Title.attr('role', 'heading');
			h1Title.attr('aria-level', '1');
		});
		$('.h2').each(function () {
			var h2Title = $(this);
			h2Title.attr('role', 'heading');
			h2Title.attr('aria-level', '1');
		});
		$('.h3').each(function () {
			var h3Title = $(this);
			h3Title.attr('role', 'heading');
			h3Title.attr('aria-level', '1');
		});
		$('.h4').each(function () {
			var h4Title = $(this);
			h4Title.attr('role', 'heading');
			h4Title.attr('aria-level', '1');
		});
		$('.h5').each(function () {
			var h5Title = $(this);
			h5Title.attr('role', 'heading');
			h5Title.attr('aria-level', '1');
		});
		/****
		*************
		*************
				Sliders
		*************
		*************
		*****/

		/*
			** Logo Banner Slider
			*/

		// Loop through each instance of a slider and initialize based on the matching class
		var logoSliders = document.getElementsByClassName('rpb-logo-slider');
		for (var logoCount = 0; logoCount < logoSliders.length; logoCount++) {
			// mount a new slider with the Intersection observer
			var logoSplide = new Splide(logoSliders[logoCount]).mount({ Intersection });
			logoSplide.on('intersection:in', function (entry) {
				$(entry.target).find('.logo-banner-img').each(function () {
					var logoAttr = $(this).attr('data-src');
					$(this).attr('src', logoAttr);
				});
			});
		}

		/*
			** Hero Slider
			*/

		// Loop through each instance of a slider and initialize based on the matching class
		// var heroSliders = document.getElementsByClassName('rpb-hero-slider');
		// for (var heroCount = 0; heroCount < heroSliders.length; heroCount++) {
		//     new Splide(heroSliders[heroCount]).mount();
		// }
		// Loop through each instance of a slider and initialize based on the matching class
		var heroSliders = document.getElementsByClassName('rpb-hero-slider');
		for (var heroSliderCount = 0; heroSliderCount < heroSliders.length; heroSliderCount++) {
			// mount a new slider with the Intersection observer
			var heroSlider = new Splide(heroSliders[heroSliderCount]).mount({ Intersection });
			// on intersection, set the data-src of the slider img to the src
			heroSlider.on('intersection:in', function (entry) {
				$(entry.target).find('.lazy').each(function () {
					if (entry.isIntersecting) {
						var imgAttr = $(this).attr('data-src');
						$(this).attr('src', imgAttr);
						var bgImage = $(this).attr('data-bgimage');
						if (bgImage) {
							this.style.backgroundImage = bgImage;
						}
						var bgSize = $(this).attr('data-bgsize');
						if (bgSize) {
							this.style.backgroundSize = bgSize;
						}
						var bgPosition = $(this).attr('data-bgposition');
						if (bgPosition) {
							this.style.backgroundPosition = bgPosition;
						}
						var bgRepeat = $(this).attr('data-bgrepeat');
						if (bgRepeat) {
							this.style.backgroundRepeat = bgRepeat;
						}
					}
				});
			});
		}

		/*
			** Bootstrap Cards Slider
			*/

		// Loop through each instance of a slider and initialize based on the matching class
		var cardSliders = document.getElementsByClassName('bootstrap-cards-slider');
		for (var cardSliderCount = 0; cardSliderCount < cardSliders.length; cardSliderCount++) {
			// mount a new slider with the Intersection observer
			var cardSlider = new Splide(cardSliders[cardSliderCount]).mount({ Intersection });
			// on intersection, set the data-src of the slider img to the src
			cardSlider.on('intersection:in', function (entry) {
				$(entry.target).find('.lazy').each(function () {
					if (entry.isIntersecting) {
						var imgAttr = $(this).attr('data-src');
						$(this).attr('src', imgAttr);
						var bgImage = $(this).attr('data-bgimage');
						if (bgImage) {
							this.style.backgroundImage = bgImage;
						}
						var bgSize = $(this).attr('data-bgsize');
						if (bgSize) {
							this.style.backgroundSize = bgSize;
						}
						var bgPosition = $(this).attr('data-bgposition');
						if (bgPosition) {
							this.style.backgroundPosition = bgPosition;
						}
						var bgRepeat = $(this).attr('data-bgrepeat');
						if (bgRepeat) {
							this.style.backgroundRepeat = bgRepeat;
						}
					}
				});
			});
		}


		/*
			** Bootstrap Cards Fade Slider
			*/
		var splideFadeSlider = document.getElementsByClassName('range-fade-slider');
		for (var splideFadeSliderCount = 0; splideFadeSliderCount < splideFadeSlider.length; splideFadeSliderCount++) {
			new Splide(splideFadeSlider[splideFadeSliderCount], {
				type: "fade",
				rewind: true,
			}).mount();
		}

		/*
			** Column Content Slider
			*/

		// Loop through each instance of a slider and initialize based on the matching class
		var contentSliders = document.getElementsByClassName('range-content-slider');
		for (var contentSliderCount = 0; contentSliderCount < contentSliders.length; contentSliderCount++) {
			// mount a new slider with the Intersection observer
			var contentSlider = new Splide(contentSliders[contentSliderCount]).mount({ Intersection });
			// on intersection, set the data-src of the slider img to the src
			contentSlider.on('intersection:in', function (entry) {
				$(entry.target).find('.lazy').each(function () {
					if (entry.isIntersecting) {
						var imgAttr = $(this).attr('data-src');
						$(this).attr('src', imgAttr);
						var bgImage = $(this).attr('data-bgimage');
						if (bgImage) {
							this.style.backgroundImage = bgImage;
						}
						var bgSize = $(this).attr('data-bgsize');
						if (bgSize) {
							this.style.backgroundSize = bgSize;
						}
						var bgPosition = $(this).attr('data-bgposition');
						if (bgPosition) {
							this.style.backgroundPosition = bgPosition;
						}
						var bgRepeat = $(this).attr('data-bgrepeat');
						if (bgRepeat) {
							this.style.backgroundRepeat = bgRepeat;
						}
					}
				});
			});
		}

		/*
			** Coupon Slider
			*/

		// Loop through each instance of a slider and initialize based on the matching class
		var couponSliders = document.getElementsByClassName('rpb-coupon-slider');
		for (var couponSlidersCount = 0; couponSlidersCount < couponSliders.length; couponSlidersCount++) {
			// mount a new slider with the Intersection observer
			var couponSlider = new Splide(couponSliders[couponSlidersCount]).mount({ Intersection });
			// on intersection, set the data-src of the slider img to the src
			couponSlider.on('intersection:in', function (entry) {
				$(entry.target).find('.lazy').each(function () {
					if (entry.isIntersecting) {
						var imgAttr = $(this).attr('data-src');
						$(this).attr('src', imgAttr);
						var bgImage = $(this).attr('data-bgimage');
						if (bgImage) {
							this.style.backgroundImage = bgImage;
						}
						var bgSize = $(this).attr('data-bgsize');
						if (bgSize) {
							this.style.backgroundSize = bgSize;
						}
						var bgPosition = $(this).attr('data-bgposition');
						if (bgPosition) {
							this.style.backgroundPosition = bgPosition;
						}
						var bgRepeat = $(this).attr('data-bgrepeat');
						if (bgRepeat) {
							this.style.backgroundRepeat = bgRepeat;
						}
					}
				});
			});
		}

		/*
		** Image Slider
		*/

		// Loop through each instance of a slider and initialize based on the matching class
		var imageSliders = document.getElementsByClassName('rpb-image-slider');
		for (var imageSliderCount = 0; imageSliderCount < imageSliders.length; imageSliderCount++) {
			// assign slider elements to variables
			var sliderID = imageSliders[imageSliderCount].id;
			var sliderElement = document.getElementById(sliderID);
			var sliderParentID = sliderElement.parentElement.id;
			var sliderParentElement = document.querySelector('#' + sliderParentID);
			var thumbnailSliderElement = sliderParentElement.querySelector('.rpb-image-slider-thumbnails');

			// slider without thumbnails
			if (!thumbnailSliderElement) {
				var imageSlider = new Splide(imageSliders[imageSliderCount], {
					// Add your configurations for sliders without thumbnails
				}).mount({ Intersection });

				imageSlider.on('intersection:in', function (entry) {
					$(entry.target).find('.lazy').each(function () {
						if (entry.isIntersecting) {
							var imgAttr = $(this).attr('data-src');
							$(this).attr('src', imgAttr);
							var bgImage = $(this).attr('data-bgimage');
							if (bgImage) {
								this.style.backgroundImage = bgImage;
							}
							var bgSize = $(this).attr('data-bgsize');
							if (bgSize) {
								this.style.backgroundSize = bgSize;
							}
							var bgPosition = $(this).attr('data-bgposition');
							if (bgPosition) {
								this.style.backgroundPosition = bgPosition;
							}
							var bgRepeat = $(this).attr('data-bgrepeat');
							if (bgRepeat) {
								this.style.backgroundRepeat = bgRepeat;
							}
						}
					});
				});
			} else {
				// assign the thumbnail slider a variable
				var thumbnailSlider = new Splide(thumbnailSliderElement, {
					rewind: true,
					fixedWidth: 75,
					fixedHeight: 75,
					isNavigation: true,
					arrows: false,
					gap: 10,
					pagination: false,
					cover: true,
					dragMinThreshold: {
						mouse: 4,
						touch: 10,
					},
					breakpoints: {
						640: {
							fixedWidth: 66,
							fixedHeight: 38,
						},
					},
				});

				var mainSlider = new Splide(imageSliders[imageSliderCount]);

				// mount the sliders
				mainSlider.sync(thumbnailSlider);
				mainSlider.mount();
				thumbnailSlider.mount();
			}
		}

		/*
			** Customer Reviews Slider
			*/

		// Loop through each instance of a slider and initialize based on the matching class
		var reviewSliders = document.getElementsByClassName('customer-reviews-slider');
		for (var reviewSlidersCount = 0; reviewSlidersCount < reviewSliders.length; reviewSlidersCount++) {
			// mount a new slider with the Intersection observer
			var reviewSlider = new Splide(reviewSliders[reviewSlidersCount]).mount({ Intersection });
			// on intersection, set the data-src of the slider img to the src
			reviewSlider.on('intersection:in', function (entry) {
				// console.log( 'in', entry.target );
				$(entry.target).find('.review-img').each(function () {
					//console.log('found' + i);
					var reviewAttr = $(this).attr('data-src');
					$(this).attr('src', reviewAttr);
					var bgImage = $(this).attr('data-bgimage');
					if (bgImage) {
						this.style.backgroundImage = bgImage;
					}
					var bgSize = $(this).attr('data-bgsize');
					if (bgSize) {
						this.style.backgroundSize = bgSize;
					}
					var bgPosition = $(this).attr('data-bgposition');
					if (bgPosition) {
						this.style.backgroundPosition = bgPosition;
					}
					var bgRepeat = $(this).attr('data-bgrepeat');
					if (bgRepeat) {
						this.style.backgroundRepeat = bgRepeat;
					}
				});
			});
		}

		/*
			** Blog Feed Slider
			*/

		// Loop through each instance of a slider and initialize based on the matching class
		var blogFeeds = document.getElementsByClassName('blog-feed-slider');
		for (var blogFeedsCount = 0; blogFeedsCount < blogFeeds.length; blogFeedsCount++) {
			new Splide(blogFeeds[blogFeedsCount]).mount();
		}

		/****
		*************
		*************
				Additional Page Builder Functions
		*************
		*************
		*****/

		// Photo Gallery Lightgallery
		var galleryLightbox = document.getElementsByClassName('gallery-lightbox');
		for (var galleryCount = 0; galleryCount < galleryLightbox.length; galleryCount++) {
			var galleryID = galleryLightbox[galleryCount].id;
			var galleryElement = document.getElementById(galleryID);
			var gallerySelector = $('.photo-gallery-photo');
			var galleryAlt = $(gallerySelector).attr('data-alt');
			lightGallery(galleryElement, {
				plugins: [lgZoom, lgThumbnail, lgHash, lgShare],
				thumbnail: false,
				selector: '.photo-gallery-photo',
				alt: galleryAlt,
			});
			console.log(galleryAlt);
		}

		/****
		*************
		*************
				WooCommerce
		*************
		*************
		*****/
		// show mobile filter on button click
		$('#shopFilter').click(function () {
			$('.basebuild-shop-filter-in').slideToggle();
		});
		// review form on button click
		$('#addRevewButton').click(function () {
			$('#commentform').slideToggle();
		});
		if (!String.prototype.getDecimals) {
			String.prototype.getDecimals = function () {
				var num = this,
					match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
				if (!match) {
					return 0;
				}
				return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
			}
		}
		// Quantity "plus" and "minus" buttons
		$(document.body).on('click', '.plus, .minus', function () {
			var $qty = $(this).closest('.quantity').find('.qty'),
				currentVal = parseFloat($qty.val()),
				max = parseFloat($qty.attr('max')),
				min = parseFloat($qty.attr('min')),
				step = $qty.attr('step');
			// Format values
			if (!currentVal || currentVal === '' || currentVal === 'NaN') currentVal = 0;
			if (max === '' || max === 'NaN') max = '';
			if (min === '' || min === 'NaN') min = 0;
			if (step === 'any' || step === '' || step === undefined || parseFloat(step) === 'NaN') step = 1;
			// Change the value
			if ($(this).is('.plus')) {
				if (max && (currentVal >= max)) {
					$qty.val(max);
				} else {
					$qty.val((currentVal + parseFloat(step)).toFixed(step.getDecimals()));
				}
			} else {
				if (min && (currentVal <= min)) {
					$qty.val(min);
				} else if (currentVal > 0) {
					$qty.val((currentVal - parseFloat(step)).toFixed(step.getDecimals()));
				}
			}
			// Trigger change event
			$qty.trigger('change');
		});

		/****
						*************
						*************
								MSO Functionality
						*************
						*************
						*****/
		var collapseElementList = [].slice.call(document.getElementsByClassName('.mso-collapse'));
		var collapseList = collapseElementList.map(function (collapseEl) {
			return new bootstrap.Collapse(collapseEl);
		});
		$('.collapse').on("show.bs.collapse", function () {
			$(this).closest('.mso-sidebar-button').addClass('active');
			$(this).closest('.mso-sidebar-button').addClass('py-3');
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').hide();
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').hide();
		});
		$('.collapse').on("hide.bs.collapse", function () {
			$(this).closest('.mso-sidebar-button').removeClass('active');
			$(this).closest('.mso-sidebar-button').removeClass('py-3');
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').show();
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').show();
		});
		var collapseElementList = [].slice.call(document.getElementsByClassName('.mso-collapse'));
		var collapseList = collapseElementList.map(function (collapseEl) {
			return new bootstrap.Collapse(collapseEl);
		});
		$('.collapse').on('show.bs.collapse', function () {
			$(this).closest('.mso-sidebar-button').addClass('active');
			$(this).closest('.mso-sidebar-button').addClass('py-3');
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').hide();
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').hide();
		});
		$('.collapse').on('hide.bs.collapse', function () {
			$(this).closest('.mso-sidebar-button').removeClass('active');
			$(this).closest('.mso-sidebar-button').removeClass('py-3');
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-more').show();
			$(this).closest('.mso-sidebar-button').find('.mso-sidebar-address').show();
		});
		// Fullscreen Map Expand
		$('#msoFullscreen').click(function () {
			$('#msoMap').toggleClass('fullscreen');
			$('.msofullscreen').toggleClass('d-none');
			$('.msocompress').toggleClass('d-block');
			$('.store-locator-sidebar').toggleClass('rounded-0');
			$('.store-locator-fullscreen-in').toggleClass('rounded-bottom-0');
			$('body').toggleClass('overflow-hidden');
		});

		// Show filters (desktop)
		$('#showFilters').click(function () {
			$('#msoFilterPanel').css('width', '200%');
			$('#msoFilterPanel').css('opacity', '1');
			$('#msoFilterPanel').css('visibility', 'visible');
			$('.mso-filter-back').css('visibility', 'visible');
		});
		// Hide filters (desktop)
		$('#msoFilterBack').click(function () {
			$('#msoFilterPanel').css('width', '0');
			$('#msoFilterPanel').css('opacity', '0');
			$('#msoFilterPanel').css('visibility', 'hidden');
			$('.mso-filter-back').css('visibility', 'hidden');
		});

		// Show filters (mobile)
		$('#showFiltersMobile').click(function () {
			$('#msoFilterPanel').css('opacity', '1');
			$('#msoFilterPanel').css('visibility', 'visible');
			$('.mso-filter-back').css('visibility', 'visible');
		});
		// Hide filters (mobile)
		$('#msoFilterBackMobile').click(function () {
			$('#msoFilterPanel').css('width', '0');
			$('#msoFilterPanel').css('opacity', '0');
			$('#msoFilterPanel').css('visibility', 'hidden');
			$('.mso-filter-back').css('visibility', 'hidden');
		});

		// Apply filters (hide filter)
		// Desktop
		$('#submitMsoFilters').click(function () {
			$('#msoFilterPanel').css('width', '0');
			$('#msoFilterPanel').css('opacity', '0');
			$('#msoFilterPanel').css('visibility', 'hidden');
			$('.mso-filter-back').css('visibility', 'hidden');
		});
		$('#submitMsoFiltersMobile').click(function () {
			$('#msoFilterPanel').css('opacity', '0');
			$('#msoFilterPanel').css('visibility', 'hidden');
			$('.mso-filter-back').css('visibility', 'hidden');
		});
		/*
		** Location Popup Slider
		*/
		var locationPopups = document.getElementsByClassName('location-popup-slider');
		for (var locationPopupsCount = 0; locationPopupsCount < locationPopups.length; locationPopupsCount++) {
			new Splide(locationPopups[locationPopupsCount]).mount();
		}
		/*
		** Dutchie deep linkinking from location modal
		*/
		var locationModal = document.getElementById('openLocationsModal');
		if (locationModal) {
			locationModal.addEventListener('shown.bs.modal', function (event) {
				let buttonClicked = event.relatedTarget;
				let productType = jQuery(buttonClicked).attr('data-category');
				let linkAppend = '?dtche%5Bcategory%5D=' + productType + '#menu';
				jQuery('#openLocationsModal .location-popup-link').each(function () {
					this.href += linkAppend;
				});
			});
		}
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
